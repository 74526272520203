import React, { useEffect, useState, useRef } from 'react';
import ImageCarousel from './ImageCarousel';
import Footer from './Footer';

const Penthouse = () => {
  const [bgImage, setBgImage] = useState('/images/101.jpeg');
  const [showMore, setShowMore] = useState(false)
  const [colorSchemeActive, setColorSchemeActive] = useState('')
  const [showResidenceLogo, setShowResidenceLogo] = useState(true)

  const images = [
    '/images/penthouse-render-1.png',
    '/images/penthouse-render-2.png',
  ];


  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight; // Current scroll position
    const documentHeight = document.documentElement.scrollHeight; // Total document height

    if (scrollPosition > documentHeight - 10) { // Check if scrolled to the bottom
      setBgImage(''); // Set background image to empty
    } else if (scrollPosition > 2500) { // Check if scroll position is greater than 700
      setShowResidenceLogo(false);
      setBgImage('/images/102.jpeg');
    } else {
      setShowResidenceLogo(true);
      setBgImage('/images/101.jpeg');
    }
  };
  useEffect(() => {
    const sections = document.querySelectorAll('.fade-in-section');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: Stop observing after it has shown
          }
        });
      },
      { threshold: 0.15 }
    );
    sections.forEach(section => observer.observe(section));
    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      sections.forEach(section => observer.unobserve(section));
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="font-sans">
      <main>
        <section id="hero">
          <div className="relative">
            {showResidenceLogo &&
              <div className="fixed-image">
                <video autoPlay={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }}>
                  <source src="https://seascapehb.metaluxe.com.au//theme/dashboard/penthouse.mp4" type="video/mp4" />
                </video>
              </div>
            }
            {!showResidenceLogo &&
              <div className="fixed-image h-screen" style={{ backgroundImage: `url(${bgImage})` }}>
              </div>
            }
            <div className='scroll' onClick={scrollToNextSection}>
              <p className='text-white'>SCROLL</p>
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.81 30" className='arrow'>
                <path fill="none" stroke="#fff" d="M4.91 0v24.8"></path><path fill="#fff" d="M4.91 30l4.9-5.27H0L4.91 30z"></path>
              </svg>
            </div>
          </div>
        </section>

        <section className='bg-cream section-about' style={{ marginTop: '100vh' }} ref={nextSectionRef}>
          <div className='fade-in-section grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-6 px-6 py-8 md:p-24 items-center'>
            <div className={`${showMore ? 'show-more' : ''} pl-4 pb-20`}>
              <h4>LUXURY WITHOUT LIMITS</h4>
              <p className='py-4'>
                The Skyhome at Seascape is a breathtaking waterfront residence, unmatched in luxury and design. Here, luxury is more than a promise—it's a commitment to delivering the extraordinary through flawless design, unparalleled convenience, and world-class amenities.              </p>
              <p className='py-4'>
                As Hervey Bay’s first international hotel-branded Skyhome, this grand residence offers unlimited access to five-star services and exclusive facilities, creating an experience like no other on the coast.              </p>

              {/* <p className='py-4'>
                -Private entrance with conservatory -125sqm river-facing master bedroom wing -Vast 10m Poliform walk-in robe -Three secondary bedrooms, each with ensuite -Chef’s kitchen with commercial grade scullery -Separate formal and casual dining -Three living and entertainment areas -Wine cellar -Expansive, 72sqm west-facing terrace garden -Office / library -Parking for vehicles -Views of the Yarra River and Port Philip Bay -719 sqm
              </p>
              <div className='animate animate-penthouse'>
                <p className='py-4'>
                  5 STAR SERVICE AND AMENITY
                </p>
                <p className='pb-4'>
                  -Chef catered dinners -Culinary provisioning -In-home room dining and spa treatments -24-hour concierge, valet parking and bellman -Business services and meeting rooms -Botanical care and on-demand maintenance services -Eco-conscious housekeeping and personal shopping -Signature 1 Hotel Melbourne bars, restaurants, and events -Australia’s first Bamford Wellness Spa -World-class pool, gym, sauna, and steam room -Residents’ only terrace, library and private dining rooms -Priority access to 1 Hotel globally -Pet care and walking
                </p>
              </div> */}

              {/* <img src={`/icons/${showMore ? 'square-minus' : 'square-plus'}.svg`} onClick={() => setShowMore(!showMore)} width={18} className='cursor-pointer	' /> */}
            </div>
            <div>
              <img src="/images/penthouse-1.png" alt="" className='info' />
              <p className='text-center italic uppercase pt-3'>BREATH TAKING VIEWS</p>
            </div>
          </div>
        </section>

        <section className='bg-cream pt-8 pb-40'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/201.png" className='mt-8 w-auto m-auto' alt="" />
              <p className='text-p-m mt-3'>A PARENTS RETREAT LIKE NO OTHER</p>
            </div>
          </div>
        </section>

        <section className='py-9'>
          <div className='shell fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' style={{ 'zIndex': -1 }}>
            {showResidenceLogo && <h1 className='header text-4xl text-white tracking-widest'>SKY HOMES</h1>}
          </div>
        </section>


        <section className='py-9 bg-cream m-img-fixed'>
          <div className='fade-in-section text-center px-8'>
            <p className="text-p  mt-16">
              A LIFESTYLE CURATED TO PERFECTION <br />
              AWAITS
            </p>
          </div>
        </section>

        <section className='py-9 bg-cream'>
          <div className='w-full md:w-10/12 m-auto pt-16'>
            <ImageCarousel images={images} />
            <p className='text-p-m mt-3'>VIEW FROM LEVEL 17 LOOKING NORTH</p>
          </div>
        </section>
        <Footer />
      </main >
    </div >
  );
};

export default Penthouse;
