import React, { useState } from 'react';
import Footer from './Footer';

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    postcode: '',
    email: '',
    mobile: '',
    hearAbout: '',
    interest: '',
    comments: '',
    updates: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
    // Add your form submission logic here
  };

  return (
    <div className="bg-cream">
      <div className="max-w-4xl mx-auto pt-32">
        <p className='text-center text-2xl	tracking-wider'>Register interest for first access to designs and pricing when released</p>
        <h1 className='text-center my-12 text-2xl font-bold'>REGISTRATION OF INTEREST</h1>
        <form onSubmit={handleSubmit} className="border-black border-t-2 pt-4">
          {/* First and Last Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="First Name *"
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className='mb-3'>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name *"
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Postcode and Email */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <div>
              <input
                type="text"
                name="postcode"
                placeholder="Postcode *"
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.postcode}
                onChange={handleChange}
                required
              />
            </div>
            <div className='mb-3'>
              <input
                type="email"
                name="email"
                placeholder="Email *"
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Mobile and How did you hear about us? */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <div>
              <input
                type="text"
                name="mobile"
                placeholder="Mobile *"
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className='mb-3'>
              <input
                type="text"
                name="hearAbout"
                placeholder="How did you hear about us? *"
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.hearAbout}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* What are you interested in? */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <label htmlFor="interest" className="sr-only">What are you interested in? *</label>
            <select
              name="interest"
              id="interest"
              className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
              value={formData.interest}
              onChange={handleChange}
              required
            >
              <option value="" disabled>What are you interested in? </option>
              <option value="1-bedroom residences">1 bedroom residences from 50m²</option>
              <option value="2-bedroom residences">2 bedroom residences from 70m²</option>
              <option value="3-bedroom residences">3 bedroom residences from 100m²</option>
              <option value="The Sky Homes Collection">Sky Homes from 125m²</option>
              <option value="Commerical Leasing Enquiries">Commerical Leasing Enquiries</option>
            </select>
          </div>
          <div className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-4 placeholder-black mb-3"></div>
          {/* Comments */}
          <div>
            <textarea
              name="comments"
              placeholder="Comments"
              className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 pb-8 placeholder-black "
              value={formData.comments}
              onChange={handleChange}
            ></textarea>
          </div>

          {/* Checkbox */}
          <div className="flex items-start text-center px-40 text-black ml-2">
            <input
              type="checkbox"
              name="updates"
              className="w-8 h-8 bg-cream"
              checked={formData.updates}
              onChange={handleChange}
              id="agree"
            />
            <label for="agree" />
            <div className="text-p-xxs">
              By ticking this box you are acknowledging that you are happy to
              receive updates regarding Seascapes and the project's developer, Riverlee,
              from time-to-time.
            </div>
          </div>

          {/* Privacy Notice */}
          <div className="text-p-xxs text-center mt-8 mb-20">
            By clicking Submit you consent to our{' '}
            <a href="#" className="underline">
              Privacy Policy
            </a>
            .
          </div>

          {/* Submit Button */}
          <div className='w-100 text-center'>
            <button
              type="submit"
              className="text-black  mb-56 px-3 text-lg font-semibold border-black border-b-2 pt-4 tracking-wider	"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
