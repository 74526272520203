import React, { useEffect, useRef } from 'react';
import '../App.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.fade-in-section');
    const banners = document.querySelectorAll('.animate-slide-in-left');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: Stop observing after it has shown
          }
        });
      },
      { threshold: 0.15 }
    );
    banners.forEach(banner => observer.observe(banner));
    sections.forEach(section => observer.observe(section));


    // Cleanup on unmount
    return () => {
      sections.forEach(section => observer.unobserve(section));
      banners.forEach(banner => observer.unobserve(banner));
    };
  }, []);

  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="font-sans">
      <main>
        <section id="hero" className="bg-gray-100">
          <div className='relative'>
            <video autoPlay={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }}>
              <source src="https://seascapehb.metaluxe.com.au//theme/dashboard/background.mp4" type="video/mp4" />
            </video>
            <div className='intro'>
              <h1>
                An exclusive seaside opportunity — unparalleled, irreplaceable.
              </h1>

              <div className='flex-center mt-36 mb-6'>
                <img src="/icons/playbtn.svg" style={{ height: '25px' }} className='mr-2' />
                <p className='border-white border-b-2 intro_title '>WATCH THE FLIM</p>
              </div>
            </div>
            <div className='scroll' onClick={scrollToNextSection}>
              <p className='text-white'>SCROLL</p>
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.81 30" className='arrow'>
                <path fill="none" stroke="#fff" d="M4.91 0v24.8"></path><path fill="#fff" d="M4.91 30l4.9-5.27H0L4.91 30z"></path>
              </svg>
            </div>
          </div>
        </section>

        <div id='banner' ref={nextSectionRef}>
          <div className='animate-slide-in-left'>
            <div className="banner_slide flex-center">
              <p className='text-p'>
                REGISTER INTEREST NOW
              </p>
            </div>
          </div>
        </div>

        <section className='my-16'>
          <div className='fade-in-section mx-8'>
            <p className="text-p uppercase">
              1 bedroom residences from 50m<sup>2</sup><br />
              2 bedroom residences from 70m<sup>2</sup><br />
              3 bedroom residences from 100m<sup>2</sup><br />
              Sky Homes from 125m<sup>2</sup></p>
          </div>
        </section>
        <section className='my-28'>
          <div className='fade-in-section section-entry'>
            <p className="text-p-s">
              Located on Hervey Bay’s iconic Esplanade, this iconic development brings the region's first international 5 Star resort - along with an array of sumptuous residences amid luxury amenities, function rooms, restaurants, bars, spas, on the waterfront, offering never to be seen again coastal living.
            </p>
          </div>
        </section>
        <section className='my-16'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/111.png" className='mt-8 w-auto m-auto' alt="" />
              <p className='text-p-m mt-2'>SEASCAPES EXTERIOR
                <em className="artists-text">— Artist’s impression</em>
              </p>
            </div>
          </div>
        </section>

        <section className='my-16'>
          <div className='fade-in-section text-center px-8 py-14'>
            <h1 className='header text-4xl text-black tracking-widest'>RESIDENCES</h1>
            <p className="text-p-xxxs my-8 section-entry py-8">
              Featuring meticulously designed, light-filled residences, this landmark development redefines luxury waterfront living.
            </p>
            <Link to="/residences" reloadDocument>
              <button className="btn">
                DISCOVER MORE
              </button>
            </Link>
          </div>
        </section>

        <section className='my-16'>
          <div className='shell'>
            <div className='fade-in-section mt-16 m-auto'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-24 md:gap-6 px-8'>
                <div>
                  <img src="/images/102.jpeg" className='h-full' alt="" />
                  <p className='text-p-m mt-3'>ARRIVAL CONSERVATORY, SKY HOMES —
                    <em className="artists-text">Artist’s impression</em>
                  </p>
                </div>

                <div>
                  <img src="/images/103.png" className='h-full' alt="" />
                  <p className='text-p-m mt-3'>COCKTAIL LOUNGE, SKY HOMES —
                    <em className="artists-text"> Artist’s impression</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className='my-16'>
          <div className='fade-in-section text-center px-8 py-14'>
            <h1 className='header text-4xl text-black tracking-widest'>SKY HOMES</h1>

            <p className="text-p-xxxs my-8 section-entry py-8">
              The Sky Homes at Seascape are one-of-a-kind waterfront residences where
              luxury is a promise, not just a word. Designed for those who demand the
              extraordinary, they offer 250 square meters of meticulously crafted opulence,
              unrivaled convenience, and world-class amenities. These sun-drenched sanctuaries
              feature expansive spaces and breathtaking views of Hervey Bay, setting a new standard
              in coastal living.
            </p>
            <Link to="/skyhomes" reloadDocument>
              <button className="btn">
                DISCOVER MORE
              </button>
            </Link>
          </div>
        </section>

        <section className='my-16'>
          <div className='fade-in-section'>
            <img src="/images/108.png" className='mt-10 w-full' alt="" />
            <p className='text-p-m mt-3'>SEASCAPE SKY HOMES MASTER SUITE
            </p>
          </div>
        </section >

        <section className='my-16'>
          <div className='fade-in-section text-center px-8 py-14'>
            <h1 className='header text-4xl text-black tracking-widest'>Sheraton Hervey Bay Hotel</h1>

            <p className="text-p-xxxs my-8 section-entry py-8">
              This exclusive lifestyle goes beyond the luxury of your waterfront home, offering
              access to world-class amenities and a dedicated team at your service. Enjoy the
              benefits of Hervey Bay’s first international hotel-branded residences and five-star
              hotel services.
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-16'>
          <div className='fade-in-section'>
            <img src="/images/104.png" className='mt-10 w-full' alt="" />
            <p className='text-p-m mt-3'> THE RESORT POOL
            </p>
          </div>
        </section>

        <section className='my-16'>

          <div className='fade-in-section text-center mx-8 py-14'>
            <h1 className='header text-4xl text-black tracking-widest'>Architecture</h1>

            <p className="text-p-xxxs my-8 section-entry py-8">
              At the heart of Seascape is its stunning location along Torquay Beach in Hervey Bay.
              This bold, contemporary design perfectly complements the natural beauty of the coastline,
              offering an unparalleled connection to the beach while embracing the relaxed coastal lifestyle
              that Hervey Bay is known for. The development brings new life to this iconic area, creating a
              seamless blend of luxury living and the rich, vibrant atmosphere of the seaside.
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-16'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/109.png" className='mt-16 w-auto m-auto' alt="" />
              <p className='text-center pt-3'>SEASCAPES EXTERIOR, NORTH WHARF
              </p>
            </div>
          </div>
        </section>

        <section className='my-44'>
          <div className='shell'>
            <div className='fade-in-section'>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-8 pb-4'>FRASER COAST</p>
                  </div>
                  <img src="/images/105.jpeg" className='my-5 h-full' alt="" />
                  <p className='text-p-xs pt-2 pb-3'>Seascape redefines coastal living, offering a new perspective on luxury and the beauty of Hervey Bay...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-8 pb-4'>RESIDENCES</p>
                  </div>
                  <img src="/images/106.png" className='my-5 h-full' alt="" />
                  <p className='text-p-xs pt-2 pb-3'>Seascape’s bold exterior offers uniquely spacious residences, designed for coastal living and more...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-8 pb-4'>SPA</p>
                  </div>
                  <img src="/images/107.png" className='my-5 h-full' alt="" />
                  <p className='text-p-xs pt-2 pb-3'>Seascape's spa and relaxation facilities offer unmatched luxury, providing the ultimate escape by the sea...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div >
  );
};

export default Home;
