import React from 'react';
import Slider from 'react-slick';

const ImageCarousel = ({ isColorScheme, colorSchemeActive, images }) => {
  // Slider settings
  const settings = {
    dots: !isColorScheme,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    arrows: false
  };

  const imagePairs = { bgImage: 'https://seafarers-residences.com.au/wp-content/uploads/2020/05/residences-colour-scheme-nocturne-1200x720.jpg', overlayImage: 'https://seafarers-residences.com.au/wp-content/uploads/2020/05/RIL1350_Seafarers_S120_INT_KitchenLight_Final-1200x720.jpg' };

  return (
    <div className="carousel-container">
      {!isColorScheme &&
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} className="w-full h-auto" />
            </div>
          ))}
        </Slider>
      }
      {isColorScheme &&
        <Slider {...settings}>
          <div className="image-slide">
            <div className="image-container">

              <img src={imagePairs.overlayImage} alt="Overlay" />
              <div className="resize" style={{ width: colorSchemeActive == '' ? '50%' : colorSchemeActive == 'nocturne' ? '100%' : '0%' }}>
                <img src={imagePairs.bgImage} alt="Background" />
              </div>
            </div>
          </div>
        </Slider>
      }
    </div>
  );
};

export default ImageCarousel;
