import React, { useEffect, useState, useRef } from 'react';
import ImageCarousel from './ImageCarousel';
import Footer from './Footer';

const Residence = () => {
  const [bgImage, setBgImage] = useState('/images/101.jpeg');
  const [showMore, setShowMore] = useState(false)
  const [colorSchemeActive, setColorSchemeActive] = useState('')
  const [showResidenceLogo, setShowResidenceLogo] = useState(true)

  const images = [
    // '/images/Living.jpeg',
    '/images/residence-carousel.png'
  ];

  const viewLevelimages = [
    '/images/level1.png',
    '/images/level2.png',
  ]

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight; // Current scroll position
    const documentHeight = document.documentElement.scrollHeight; // Total document height

    if (scrollPosition > documentHeight - 10) { // Check if scrolled to the bottom
      setBgImage(''); // Set background image to empty
    } else if (scrollPosition > 2500) { // Check if scroll position is greater than 700
      setShowResidenceLogo(false);
      setBgImage('/images/110.png');
    } else {
      setShowResidenceLogo(true);
      setBgImage('/images/101.jpeg');
    }
  };
  useEffect(() => {
    const sections = document.querySelectorAll('.fade-in-section');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: Stop observing after it has shown
          }
        });
      },
      { threshold: 0.15 }
    );
    sections.forEach(section => observer.observe(section));
    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      sections.forEach(section => observer.unobserve(section));
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main>
      <section id="hero">
        <div className="relative">
          {showResidenceLogo &&
            <div className="fixed-image">
              <video autoPlay={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }}>
                <source src="https://seascapehb.metaluxe.com.au//theme/dashboard/residences.mp4" type="video/mp4" />
              </video>
            </div>
          }
          {!showResidenceLogo &&
            <div className="fixed-image h-screen" style={{ backgroundImage: `url(${bgImage})` }}>
            </div>
          }
          {showResidenceLogo &&
            <div className='scroll' onClick={scrollToNextSection}>
              <p className='text-white'>SCROLL</p>
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.81 30" className='arrow'>
                <path fill="none" stroke="#fff" d="M4.91 0v24.8"></path><path fill="#fff" d="M4.91 30l4.9-5.27H0L4.91 30z"></path>
              </svg>
            </div>
          }
        </div>
      </section>

      <section className='bg-blue-1 section-about' style={{ marginTop: '100vh' }} ref={nextSectionRef}>
        <div className={`grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-6 px-6 py-8 md:p-24 items-center`}>
          <div className={`${showMore ? 'show-more' : ''} pl-4 pb-20`}>
            <h4>DELIGHT IN EVERY MAGNIFICENT DETAIL</h4>
            <p>
              Seascape's exterior not only makes a bold statement but also offers expansive, thoughtfully designed residences.
            </p>
            <p className='py-4'>
              From one-bedroom apartments to four-bedroom sky homes, each home is crafted to maximize natural light and stunning seafront views.
            </p>

            <p className='pb-4 text-black'>
              Lush greenery, vines, and palms adorn every level and balcony, making Seascape Australia’s greenest building.
            </p>

          </div>
          <div >
            <img src="/images/image-2.png" alt="" className='info' />
            <p className='text-center italic pt-2'>Could this be Australia's greenest built form ?</p>
          </div>
        </div>
      </section>

      <section className='bg-cream'>
        <div className='w-10/12 m-auto py-48'>
          <ImageCarousel images={images} />
          <p className='text-p-m mt-3'>LIVING DINING
            <em className="artists-text">— Artist’s impression</em>
          </p>
        </div>
      </section>

      <section className='py-16'>
        <div className='shell fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' style={{ 'zIndex': -1 }}>
          {showResidenceLogo &&
            <div className='text-fade-in'>
              <h1 className='header text-4xl text-white tracking-widest'>RESIDENCES</h1>
            </div>
          }
          {/* {!showResidenceLogo &&
            <p className='text-p mt-32 text-white'>
              AUSTRALIA’S FIRST SUSTAINABLE,<br />
              HIGHLY DURABLE, PORCELAIN SNAIDERO <br />
              ITALIAN KITCHEN.
            </p>
          } */}
        </div>
      </section>


      <section className='py-40 bg-cream m-img-fixed '>
        <div className='shell fade-in-section text-center px-24 py-8'>
          <div className='flex-around pb-12'>
            <div className='flex justify-center items-center flex-row md:flex-col' onMouseEnter={() => setColorSchemeActive('nocturne')} onMouseLeave={() => setColorSchemeActive('')}>
              <button className='btn-color-scheme'>Nocturne</button>
              <span className='btn-label'>view colour scheme</span>
            </div>
            <div className='flex justify-center items-center flex-row md:flex-col' onMouseEnter={() => setColorSchemeActive('alba')} onMouseLeave={() => setColorSchemeActive('')}>
              <button className='btn-color-scheme btn-color-scheme-white'>ALBA</button>
              <span className='btn-label'>view colour scheme</span>
            </div>
          </div>

          <ImageCarousel isColorScheme={true} colorSchemeActive={colorSchemeActive} />
          <p className='text-p-m mt-2'>SNAIDERO KITCHEN
            <em className="artists-text">— Artist’s impression</em>
          </p>
        </div>
      </section>

      <section className='py-2 bg-cream'>
        <div className="fade-in-section flex-center gap-5 text-center px-4">
          <div className='image-item'>
            <img src="https://seafarers-residences.com.au/wp-content/uploads/2020/05/Seafarers_PlaceholderImage_Residences_Gif5.jpg" />
            <p className='btn-label pt-3'>Delight in every detail</p>
          </div>
          <div className='image-item'>
            <img src="https://seafarers-residences.com.au/wp-content/uploads/2020/05/Seafarers_PlaceholderImage_Residences_test.gif" />
            <p className='btn-label pt-3'>Internally illuminated pot drawers</p>
          </div>
        </div>
      </section>


      <section className='py-1 bg-cream'>
        <div className='fade-in-section text-center px-8 section-entry'>
          <p className="text-p my-44 uppercase">
            As a symbol of luxury waterfront living, Seascape's
            expansive interiors stand apart, seamlessly connecting
            with the ocean and embodying the essence of
            coastal living in Hervey Bay.
          </p>

        </div>
      </section>

      <section className='pt-2 pb-44 bg-cream'>
        <div className='w-10/12 m-auto'>
          <ImageCarousel images={viewLevelimages} />
          <p className='text-p-m mt-2'>VIEW FROM LEVEL 17 LOOKING EAST</p>
        </div>
      </section>
      <Footer />
    </main >
  );
};

export default Residence;
