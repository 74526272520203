import React from 'react';

function Restaurant() {
  return (
    <div className="map" >
      <img decoding="async" useMap="#Tennancy" src="/images/updated-tenancys-map.png" alt="Fullscreen"
        style={{ "width": "100%", height: "100%" }} data-recalc-dims="1" />
      <p></p>
      <map name="Tennancy" >
        <area target="_blank" alt="T12" title="T12" href="https://iframe.mediadelivery.net/play/90575/f77385d1-9196-46b2-aaf5-5f734960baf4" coords="602.2947131608548,74.36266666666666,682.474690663667,205.00666666666666" shape="rect" data-coords="2231,292,2528,805" />
        <area target="_blank" alt="T11" title="T11" href="https://iframe.mediadelivery.net/play/90575/bcc2f5cd-c127-4e27-bf5a-3160d21ee6bd" coords="702.7221597300338,80.984,772.9133858267716,200.168" shape="rect" data-coords="2603,318,2863,786" />
        <area target="_blank" alt="T10" title="T10" href="https://iframe.mediadelivery.net/play/90575/6028d62d-6a8d-470e-9b9a-84da1d63d311" coords="789.3813273340833,146.43333333333334,863.0821147356579,193.03733333333332" shape="rect" data-coords="2924,575,3197,758" />
        <area target="_blank" alt="T9" title="T9" href="https://iframe.mediadelivery.net/play/90575/daf202a7-29f0-485c-be5f-b045c53be39e" coords="901.1473565804275,75.63600000000001,953.5208098987626,200.168" shape="rect" data-coords="3338,297,3532,786" />
        <area target="_blank" alt="T8" title="T8" href="https://iframe.mediadelivery.net/play/90575/240f117f-d2d1-4dcb-878e-f1a62a75b326" coords="1001.8447694038246,155.856,1069.066366704162,202.71466666666666" shape="rect" data-coords="3711,612,3960,796" />
        <area target="_blank" alt="T7" title="T7" href="https://iframe.mediadelivery.net/play/90575/c8e4792b-f8dd-487e-a84c-7892476c22a1" coords="1113.6107986501688,147.452,1172.193475815523,199.14933333333332" shape="rect" data-coords="4125,579,4342,782" />
        <area target="_blank" alt="T6" title="T6" href="https://iframe.mediadelivery.net/play/90575/20ea29ba-55e1-40f9-ba30-365ea432ebb9" coords="1205.3993250843644,154.83733333333333,1258.8526434195726,206.28" shape="rect" data-coords="4465,608,4663,810" />
        <area target="_blank" alt="T5" title="T5" href="https://iframe.mediadelivery.net/play/90575/d7fdff5f-bcf1-44ce-9296-889657979977" coords="1037.48031496063,436.4986666666667,1074.1957255343082,483.3573333333334" shape="rect" data-coords="3843,1714,3979,1898" />
        <area target="_blank" alt="T2" title="T2" href="https://iframe.mediadelivery.net/play/90575/5c80f7bb-8d11-4752-adc4-74575c16264c" coords="995.3655793025872,503.73066666666665,1027.2215973003374,545.7506666666667" shape="rect" data-coords="3687,1978,3805,2143" />
        <area target="_blank" alt="T3" title="T3" href="https://iframe.mediadelivery.net/play/90575/229f8ed0-d0b8-4901-ada9-e87c740db1f2" coords="894.9381327334083,455.85333333333335,955.9505061867267,532.5079999999999" shape="rect" data-coords="3315,1790,3541,2091" />
        <area target="_blank" alt="T4" title="T4" href="https://iframe.mediadelivery.net/play/90575/c6dab539-5c36-44a5-9df5-444ad06dece7" coords="978.8976377952756,439.0453333333333,1024.7919010123733,479.79200000000003" shape="rect" data-coords="3626,1724,3796,1884" />
        <area target="_blank" alt="T1" title="T1" href="https://iframe.mediadelivery.net/play/90575/34f6fdf8-799d-49c7-8a05-d8b66ac7ed6c" coords="1042.3397075365579,503.73066666666665,1076.8953880764905,540.9119999999999" shape="rect" data-coords="3861,1978,3989,2124" />
        <area target="_blank" alt="T14" title="T14" href="https://iframe.mediadelivery.net/play/90575/2e07bee8-9524-40dc-9e11-00b3c5a37cb7" coords="639.2800899887513,455.85333333333335,697.8627671541058,516.9733333333334" shape="rect" data-coords="2368,1790,2585,2030" />
        <area target="_blank" alt="T13" title="T13" href="https://iframe.mediadelivery.net/play/90575/f0ed97bb-536d-471b-bb9e-58d9a4900b1a" coords="952.1709786276715,299.74266666666665,1004.2744656917885,339.47066666666666" shape="rect" data-coords="3527,1177,3720,1333" />
      </map>
    </div>
  );
}

export default Restaurant;
