import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header, Sidebar, Home, Penthouse, Residence, Register, Restaurant } from './';


function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      '/': 'Esplanade Hervey Bay',
      '/skyhomes': 'Sky Homes',
      '/residences': 'Residences',
      '/register': 'Register',
      '/restaurants-bars-spa-retail': 'Restaurants'
    };

    document.title = `Seascape | ${pageTitles[location.pathname] || 'Unknown Page'}`;
  }, [location]);
}

function Layout({ toggleSidebar, isSidebarOpen, setIsSidebarOpen, isHeaderColorActive }) {
  usePageTitle(); // Hook for setting the page title

  return (
    <>
      <Header toggleSidebar={toggleSidebar} isHeaderColorActive={isHeaderColorActive} setIsSidebarOpen={setIsSidebarOpen} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/skyhomes" element={<Penthouse />} />
        <Route path="/residences" element={<Residence />} />
        <Route path="/restaurants-bars-spa-retail" element={<Restaurant />} />

        <Route path="/register" element={<Register />} />

      </Routes>
    </>
  );
}

export default Layout;
